<template>
  <section>
    <!-- Header -->
    <header class="process-steps-header"
      :class="{ 'sticky-top': !layout.integratedHeader }">
      <!-- Title -->
      <h3 class="text-align-center">
        {{ $t(...headerTitle) }}
      </h3>

      <!-- Stepper -->
      <!-- TODO: Refactor to use the ProcessSteps Component -->
      <div class="flex-row-centered steps wl-steps">
        <div class="m-0-5 width-140px">
          <el-button v-if="stepIdx > 0"
            type="primary"
            @click="goToLastStep">
            <i class="fas fa-arrow-circle-left"
              aria-hidden="true" /> {{ $t('general.back') }}
          </el-button>
        </div>
        <div class="flex-1">
          <el-steps :active="stepIdx"
            :align-center="true"
            class="m-auto mt-1-3 max-width-500px">
            <el-step v-for="(step, index) in allSteps"
              :key="index"
              :title="step.title"
              :class="calcStepStatus(step, index) === 'wait' ? '' : 'cursor-pointer'"
              :status="calcStepStatus(step, index)"
              @click.native="e => handleStepClick(e, index)" />
          </el-steps>
        </div>
        <div class="text-align-right m-0-5 width-140px">
          <el-button v-if="!isFinalStep"
            type="primary"
            class="step-button"
            :plain="!canNextStep"
            @click="goToNextStep">
            {{ $t('general.next') }}
            <i class="fas fa-arrow-circle-right"
              aria-hidden="true" />
          </el-button>
        </div>
      </div>
    </header>

    <section class="p-20px flex-column flex-align-center">
      <!-- Details Card -->
      <end-details-card id="intro-step-2"
        :left-icon-id="isMcr ? 'MCR' : 'PORT'"
        :detail-lines="detailLines"
        :right-image-src="locationSelect ? `https://media.megaport.com/datacentre/${locationSelect.dc.id}/62x30.png` : ''"
        :diversity-zone="isMcr ? createMegaportForm.diversityZone : null" />

      <!-- Price Box -->
      <price-box v-if="!isSummaryStep && price"
        :monthly-rate="finalMonthlyRate"
        :currency="price.currency" />

      <!-- Primary Content -->
      <!-- TODO: Refactor to break this into smaller bits -->
      <div class="content-panel">
        <!-- Instructions on how to create a port paragraph -->
        <i18n v-if="!isMcr && !isSummaryStep"
          tag="div"
          path="ports.how-to-create-port-paragraph"
          class="how-to-create-port"
          data-testid="how-to-create-port-paragraph">
          <a @click="trackDocLinks">{{ $t('ports.how-to-create-port-text') }}</a>
        </i18n>

        <!-- Info Box: Restricted Location due to associated Megaport Internet VXC(s) -->
        <el-alert v-if="hasMegaportInternetVxc && currentStep.id === 'select-location'"
          type="warning"
          :closable="false"
          :title="$t('general.important-information')"
          class="max-width-500px mx-auto mb-1-5 py-1-5 line-height-initial">
          <p class="color-warning">
            {{ $t('ports.megaport-internet-restricted-location-reason', { productType: isMcr ? $t('productNames.mcr') : $t('productNames.port') }) }}
          </p>
          <p class="color-warning">
            {{ $t('ports.megaport-internet-restricted-location-workaround', { productType: isMcr ? $t('productNames.mcr') : $t('productNames.port') }) }}
          </p>
        </el-alert>

        <!-- Form -->
        <el-form ref="createMegaportForm"
          v-loading="!locationsReady"
          :model="createMegaportForm"
          :rules="createMegaportRules"
          @validate="formFieldValidated">
          <!-- Step: Select Location -->
          <div v-if="currentStep.id === 'select-location'"
            id="intro-select-location">
            <div class="content-column">
              <el-form-item :label="$t('general.select-type-location', { type: $t(isMcr ? 'productNames.mcr' : 'productNames.port') })"
                prop="locationSelectedId">
                <mu-item-selector :items="displayLocations"
                  :selected-id="createMegaportForm.locationSelectedId"
                  :sort-string-function="sortLocationString"
                  :to-string-function="locationToString"
                  id-attribute="id"
                  country-data="country"
                  table-max-height="450"
                  class="location-select-holder"
                  data-testid="location-select-holder"
                  @select="handleLocationSelect">
                  <!-- Diversity zone filter -->
                  <template #content>
                    <diversity-filter v-if="isMcr"
                      v-model="createMegaportForm.diversityZone"
                      @input="resetLocation" />
                  </template>

                  <template #cell="{data}">
                    <!-- Location logo -->
                    <img :src="`https://media.megaport.com/datacentre/${data.dc.id}/62x30.png`"
                      :alt="$t('images.datacentre-logo')"
                      class="mr-1 datacentre-logo"
                      @error="setFallbackImage($event, 'https://media.megaport.com/datacentre/fallback/MissingDC.svg')">
                    <!-- Name and address -->
                    <div>
                      <p class="location-header my-0"
                        :data-service-name="data.name">
                        {{ data.name }}
                      </p>
                      <p class="my-0">
                        {{ data.address.city }}, {{ data.country }}
                        <span v-if="data.status === 'Deployment'"
                          class="fs-1-2rem">{{ $t('general.pre-order') }}</span>
                      </p>
                    </div>
                    <!-- Diversity icon -->
                    <div v-if="isMcr && manuallySelectedDiversityZone"
                      class="ml-auto mr-0-5">
                      <i class="far fa-dot-circle diversity-zone-icon"
                        :class="`diversity-color-${createMegaportForm.diversityZone}`"
                        aria-hidden="true" />
                    </div>
                  </template>
                </mu-item-selector>
              </el-form-item>
            </div>
          </div>

          <!-- Step: Configure -->
          <div v-if="currentStep.id === 'configure-port' && locationSelect"
            id="intro-config-port">
            <template v-if="isMcr">
              <el-tabs v-model="mcrConfigActiveTab">
                <el-tab-pane :label="$t('general.details')"
                  :name="DETAILS">
                  <div class="content-column content-tabs">
                    <create-port-details :port-obj="portObj"
                      :location-select="locationSelect"
                      :lag-in-design="lagInDesign"
                      :speeds="availableSpeeds"
                      :deals="deals"
                      :form="createMegaportForm"
                      :disabled-features="disabledFeatures"
                      :speed-fix="convertSpeed"
                      :available-lag-slots="availableLagSlots"
                      :allows-port-diversity="allowsPortDiversity"
                      :initial-diversity-object="initialDiversityObject"
                      :diversity-object="diversityObject"
                      :diversity-setting-changed="handleDiversityChange"
                      @update="handleCreateMegaportFormUpdate"
                      @validate="handleCreateMegaportFormValidation" />
                  </div>
                </el-tab-pane>
                <el-tab-pane :name="PREFIX_LISTS">
                  <template #label>
                    {{ $t('ports.prefix-filter-list') }}
                  </template>

                  <div class="content-column content-tabs">
                    <p>{{ $t('ports.mcr-prefix-filter-support') }}</p>
                    <p><strong>{{ $t('ports.mcr-prefix-filter-configure') }}</strong></p>
                    <p>
                      <a href="https://docs.megaport.com/mcr/creating-mcr/"
                        target="_blank"
                        rel="noopener">{{ $t('ports.prefix-list-learn-more') }}</a>
                    </p>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </template>

            <template v-else>
              <div class="content-column">
                <create-port-details :port-obj="portObj"
                  :location-select="locationSelect"
                  :lag-in-design="lagInDesign"
                  :speeds="availableSpeeds"
                  :deals="deals"
                  :form="createMegaportForm"
                  :disabled-features="disabledFeatures"
                  :speed-fix="convertSpeed"
                  :available-lag-slots="availableLagSlots"
                  :allows-port-diversity="allowsPortDiversity"
                  :initial-diversity-object="initialDiversityObject"
                  :diversity-object="diversityObject"
                  :diversity-setting-changed="handleDiversityChange"
                  @update="handleCreateMegaportFormUpdate"
                  @validate="handleCreateMegaportFormValidation" />
              </div>
            </template>
          </div>

          <!-- Step: Summary -->
          <div v-if="currentStep.id === 'summary'"
            v-loading="updatingConfig"
            data-testid="summary-table"
            class="summary-table"
            :element-loading-text="$t('general.saving')">
            <h4 class="text-align-center pt-2 px-0 pb-1">
              {{ $t('general.summary') }}
            </h4>
            <!-- Product Type (Port or MCR) -->
            <el-row :gutter="8">
              <el-col :span="12">
                {{ $t('connections.type') | capitalizeString }}:
              </el-col>
              <el-col :span="12"
                data-summary="port-type">
                {{ isMcr ? $t('productNames.mcr') : $t('productNames.port') }}
              </el-col>
            </el-row>

            <!-- Name -->
            <el-row v-if="!allowsPortDiversity || diversitySetting !== G_DIVERSITY_ZONE_PAIR"
              :gutter="8">
              <el-col :span="12">
                {{ $t('general.name') }}:
              </el-col>
              <el-col :span="12"
                data-name="name"
                data-summary="port-name">
                {{ createMegaportForm.productName }}
              </el-col>
            </el-row>

            <!-- MCR Diversity -->
            <el-row v-if="isMcr"
              :gutter="8">
              <el-col :span="12">
                {{ $t('services.diversity-zone') }}:
              </el-col>
              <el-col :span="12"
                data-name="name"
                data-summary="port-name">
                <i class="far fa-dot-circle mr-0-5"
                  :class="`diversity-color-${createMegaportForm.diversityZone}`"
                  aria-hidden="true" />
                <span>{{ $t(`general.${createMegaportForm.diversityZone}`) }}</span>
              </el-col>
            </el-row>

            <!-- LACP Enabled -->
            <el-row v-if="!isMcr && !portObj.aggregationId"
              :gutter="8">
              <el-col :span="12">
                {{ $t('ports.lacp-enabled') }}:
              </el-col>
              <el-col :span="12"
                data-summary="port-lacp"
                data-name="lag">
                {{ createMegaportForm.lagPort ? $t('general.yes') : $t('general.no') }}
              </el-col>
            </el-row>

            <!-- LAG -->
            <el-row v-if="!isMcr && (createMegaportForm.lagPort || portObj.aggregationId)"
              :gutter="8">
              <el-col :span="12">
                {{ $t(portObj.aggregationId ? 'ports.summary-adding-to-lag' : 'ports.summary-ports-in-lag') }}:
              </el-col>
              <el-col :span="12"
                data-name="lag"
                data-summary="port-lacp-count">
                {{ createMegaportForm.lagPortCount }}
              </el-col>
            </el-row>

            <!-- Marketplace -->
            <el-row v-if="!isMcr"
              :gutter="8">
              <el-col :span="12">
                {{ $t('productNames.marketplace') }}:
              </el-col>
              <el-col :span="12"
                data-summary="port-market-visibility"
                data-name="marketplaceVisibility">
                {{
                  createMegaportForm.marketplaceVisibility ? $t('general.public') : $t('general.disabled')
                }}
              </el-col>
            </el-row>

            <!-- Service Level Reference -->
            <el-row v-if="createMegaportForm.costCentre"
              :gutter="8">
              <el-col :span="12">
                {{ $t('services.invoice-reference') }}:
              </el-col>
              <el-col :span="12"
                data-summary="port-cost-centre"
                data-name="costCentre">
                {{ createMegaportForm.costCentre }}
              </el-col>
            </el-row>

            <!-- Rate Limit / Port Speed -->
            <el-row :gutter="8">
              <el-col :span="12">
                {{ isMcr ? $t('services.rate-limit') : $t('ports.port-speed') }}:
              </el-col>
              <el-col :span="12"
                data-summary="port-speed"
                data-name="portSpeed">
                {{ convertSpeed(createMegaportForm.portSpeed) }}
              </el-col>
            </el-row>

            <!-- MCR ASN -->
            <el-row v-if="isMcr"
              :gutter="8">
              <el-col :span="12">
                {{ $t('ports.mcr-asn') }}:
              </el-col>
              <el-col :span="12"
                data-summary="port-mcr-asn"
                data-name="mcrAsn">
                {{ createMegaportForm.mcrAsn }}
              </el-col>
            </el-row>

            <!-- Initial BGP State -->
            <el-row v-if="isMcr"
              :gutter="8">
              <el-col :span="12">
                {{ $t('services.initial-bgp-state') }}:
              </el-col>
              <el-col :span="12"
                data-summary="port-mcr-asn"
                data-name="mcrAsn">
                {{
                  createMegaportForm.bgpShutdownDefault ? $t('general.shut-down') : $t('general.enabled')
                }}
              </el-col>
            </el-row>

            <!-- Term / Partner Vantage Subscription -->
            <el-row v-if="minimumTerm"
              :gutter="8">
              <el-col :span="12">
                {{ $t(isPartnerVantage ? 'partner-vantage.subscription' : 'general.term') }}:
              </el-col>
              <el-col :span="12"
                data-summary="port-term"
                data-name="term">
                {{ minimumTerm }}
              </el-col>
            </el-row>

            <!-- Port Diversity -->
            <template v-if="allowsPortDiversity">
              <!-- Diverse Port Pairs -->
              <template v-if="diversitySetting === G_DIVERSITY_ZONE_PAIR">
                <el-row :gutter="8">
                  <el-col :span="12">
                    {{ $t('ports.port-diversity') }}:
                  </el-col>
                  <el-col :span="12"
                    data-summary="diverse-pair-enabled"
                    data-name="diverse-pair-enabled">
                    {{ $t('ports.diverse-pair-label') }}
                  </el-col>
                </el-row>
                <!-- First Port Name -->
                <el-row :gutter="8">
                  <el-col :span="12">
                    {{ $t('ports.first-port-name', {thing: $t(createMegaportForm.lagPort ? 'productNames.lag' : 'productNames.port')}) }}:
                  </el-col>
                  <el-col :span="12"
                    data-summary="first-port-name"
                    data-name="first-port-name"
                    :title="$t('services.red-zone')">
                    <i class="far fa-dot-circle diversity-color-red"
                      aria-hidden="true" /> {{ createMegaportForm.productName }}
                  </el-col>
                </el-row>
                <!-- Second Port Name -->
                <el-row :gutter="8">
                  <el-col :span="12">
                    {{ $t('ports.second-port-name', {thing: $t(createMegaportForm.lagPort ? 'productNames.lag' : 'productNames.port')}) }}:
                  </el-col>
                  <el-col :span="12"
                    data-summary="second-port-name"
                    data-name="second-port-name"
                    :title="$t('services.blue-zone')">
                    <i class="far fa-dot-circle diversity-color-blue"
                      aria-hidden="true" /> {{ createMegaportForm.secondPortName }}
                  </el-col>
                </el-row>
              </template>

              <!-- Single Diversity -->
              <template v-else-if="diversitySetting">
                <el-row :gutter="8">
                  <el-col :span="12">
                    {{ $t('services.diversity-zone') }}:
                  </el-col>
                  <el-col :span="12"
                    data-summary="diversity-zone"
                    data-name="diversity-zone"
                    :title="$t(`services.${diversitySetting}-zone`)">
                    <i class="far fa-dot-circle"
                      :class="`diversity-color-${diversitySetting}`"
                      aria-hidden="true"> {{ $t(`general.${diversitySetting}`) }}</i>
                  </el-col>
                </el-row>
                <el-row v-if="diverseFrom"
                  :gutter="8">
                  <el-col :span="12">
                    {{ $t('ports.diverse-from-label') }}:
                  </el-col>
                  <el-col :span="12"
                    data-summary="diverse-from"
                    data-name="diverseFrom">
                    {{ diversePortName }}
                  </el-col>
                </el-row>
              </template>

              <!-- No Diversity -->
              <template v-else>
                <el-row :gutter="8">
                  <el-col :span="12">
                    {{ $t('ports.port-diversity') }}:
                  </el-col>
                  <el-col :span="12"
                    data-summary="diverse-pair-enabled"
                    data-name="diverse-pair-enabled">
                    {{ $t('general.none') }}
                  </el-col>
                </el-row>
              </template>
            </template>

            <!-- Monthly Rate -->
            <el-row v-if="showPricing"
              class="summary-row total"
              :gutter="8">
              <el-col :span="12">
                {{ $t('pricebook.monthly-rate') }}:
              </el-col>
              <el-col v-if="price.monthlyRate"
                :span="12"
                data-summary="port-rate">
                <span data-name="monthly-rate">{{ formattedFinalMonthlyRate }}</span>
                <p class="m-0 fs-0-8em">
                  {{ $t('pricebook.excludes-tax') }}
                </p>
              </el-col>
              <el-col v-else
                :span="12">
                {{ $t('pricebook.contact-sales') }}
              </el-col>
            </el-row>
          </div>
        </el-form>

        <!-- Action Buttons -->
        <div class="text-align-right mt-20-px">
          <!-- Cancel Button -->
          <el-button data-name="close-button"
            @click="$router.push(resolveServicesPage())">
            {{ $t('general.cancel') }}
          </el-button>

          <!-- Back Button -->
          <el-button v-if="stepIdx > 0"
            type="primary"
            @click="goToLastStep">
            <i class="fas fa-arrow-circle-left"
              aria-hidden="true" /> {{ $t('general.back') }}
          </el-button>

          <!-- Create LAG Button -->
          <el-button v-if="isFinalStep && fresh && createMegaportForm.lagPort"
            type="primary"
            data-name="add-port-button"
            data-testid="add-port-mcr-button"
            @click="goToNextStep">
            {{ $t('general.create-thing', { thing: $t('productNames.lag')}) }}
            <i class="fas fa-check-circle"
              aria-hidden="true" />
          </el-button>

          <!-- Add/Create Service Button -->
          <el-button v-else-if="isFinalStep"
            id="intro-step-4"
            type="primary"
            data-name="add-port-button"
            data-testid="add-port-mcr-button"
            @click="goToNextStep">
            {{
              $t(fresh ? 'general.add-type' : 'general.update-type', {
                type: isMcr ? $t('productNames.mcr') : createMegaportForm.lagPort ? $t('productNames.lag') : $t('productNames.port'),
              })
            }}
            <i class="fas fa-check-circle"
              aria-hidden="true" />
          </el-button>

          <!-- Next Button -->
          <el-button v-else
            id="nextButton"
            data-testid="next-button"
            type="primary"
            class="step-button"
            :plain="!canNextStep"
            @click="goToNextStep">
            {{ $t('general.next') }}
            <i class="fas fa-arrow-circle-right"
              aria-hidden="true" />
          </el-button>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
// External tools
import { mapState, mapGetters, mapActions } from 'vuex'
import { v1 as uuid } from 'uuid'
// Internal tools
import sdk from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { convertSpeed, moneyFilter, customValidationRule, locationToString, sortLocationString } from '@/helpers.js'
import { validateMcrAsn } from '@/validators.js'
import { setFallbackImage } from '@/utils/fallbackImage'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
import { calculateAutoDiversity } from '@/utils/AutoDiversity'
import { scopedPriceBook } from '@/utils/priceBook.js'
// Components
import EndDetailsCard from '@/components/ui-components/EndDetailsCard.vue'
import PriceBox from '@/components/ui-components/PriceBox.vue'
import CreatePortDetails from '@/components/ui-components/CreatePortDetails.vue'
import DiversityFilter from '@/components/ui-components/DiversityFilter.vue'
// Integrations
import { captureEvent, productTypeToEvent } from '@/utils/analyticUtils'
// Globals
import { MEGAPORT_ASN, G_PRODUCT_TYPE_MEGAPORT, G_PRODUCT_TYPE_MCR2, G_DIVERSITY_ZONE_RED, G_DIVERSITY_ZONE_BLUE, G_DIVERSITY_ZONE_AUTO } from '@/Globals.js'

// Tabs
const DETAILS = 'details'
const PREFIX_LISTS = 'prefixLists'

export default {
  name: 'CreateMegaport',

  components: {
    'end-details-card': EndDetailsCard,
    'price-box': PriceBox,
    'create-port-details': CreatePortDetails,
    'diversity-filter': DiversityFilter,
  },

  inject: ['layout', 'disabledFeatures', 'isFeatureEnabled'],

  data() {
    const port = this.$store.getters['Services/myPorts'].find(p => p.productUid === this.$route.params.productUid) || {}
    const aggregationId = this.$route.params.aggregationId ? parseInt(this.$route.params.aggregationId) : null
    const lagPrimary =
      this.$store.getters['Services/myPorts'].find(aPort => {
        return aPort.lagPrimary && (aPort.aggregationId === aggregationId || aPort.aggregationId === port.aggregationId)
      }) || {}

    if (this.$route.params.serviceType === 'mcr') {
      port.productType = G_PRODUCT_TYPE_MCR2
      port.virtual = true
    } else {
      port.productType = G_PRODUCT_TYPE_MEGAPORT
    }

    const validatePortSpeed = (rule, value, callback) => {
      if (this.fresh && !this.isMcr && this.createMegaportForm.lagPort && value < 10000) {
        callback(this.$t('ports.no-slow-ports-in-lag'))
      } else {
        callback()
      }
    }

    return {
      editingExistingUid: port.productUid, // This will only be set when the port was already saved.
      createMegaportForm: {
        locationSelectedId: port.locationId || lagPrimary.locationId || null,
        productName: port.productName || '',
        secondPortName: '',
        marketplaceVisibility: port.marketplaceVisibility || lagPrimary.marketplaceVisibility || false,
        term: port.term || lagPrimary.term || 12,
        costCentre: port.costCentre || lagPrimary.costCentre || null,
        portSpeed: port.portSpeed || lagPrimary.portSpeed || null,
        dealUid: port.dealUid || 'None',
        mcrAsn: port.config?.mcrAsn ? port.config.mcrAsn : MEGAPORT_ASN,
        bgpShutdownDefault: port.config?.bgpShutdownDefault || false,
        lagPort: !!port.lagPortCount,
        lagPortCount: port.lagPortCount || 1,
        diversityZone: port.config?.diversityZone || G_DIVERSITY_ZONE_AUTO, // MCR diversity zone
      },
      createMegaportRules: {
        locationSelectedId: { required: true, message: this.$t('validations.select-location'), trigger: 'none' },
        productName: [
          { required: true, message: this.$t('validations.enter-name'), trigger: 'blur' },
          { min: 0, max: 170, message: this.$tc('validations.max-length', 170, { max: 170 }) },
        ],
        secondPortName: [
          { required: true, message: this.$t('validations.enter-name'), trigger: 'blur' },
          { min: 0, max: 170, message: this.$tc('validations.max-length', 170, { max: 170 }) },
        ],
        marketplaceVisibility: { required: true },
        term: { required: true, trigger: 'blur' },
        portSpeed: [
          {
            required: true,
            message: port.virtual ? this.$t('validations.select-rate-limit') : this.$t('ports.select-port-speed'),
            trigger: 'change',
          },
          { validator: validatePortSpeed, trigger: 'change' },
        ],
        lagPortCount: { required: true },
        costCentre: { min: 0, max: 255, message: this.$tc('validations.max-length', 255, { max: 255 }) },
      },
      longAsnError: false,
      stepIdx: 0, // port.locationId ? 1 : 0, // Send to summary screen on edit design port.
      fresh: !port.locationId, // So we know if a design port is being created or updated.
      addingToLag: !!aggregationId,
      lagInDesign: !lagPrimary.provisioningStatus || lagPrimary.provisioningStatus === this.G_PROVISIONING_DESIGN,
      priceStore: false,
      portObj: {
        productUid: port.productUid || uuid(),
        productName: port.productName || '',
        virtual: typeof port.virtual === 'boolean' ? port.virtual : false,
        productType: port.productType,
        buyoutPort: port.buyoutPort || false,
        config: {},
        aggregationId: port.aggregationId || aggregationId,
        lagId: port.lagId || this.$route.params.lagId,
        lagPrimary: typeof port.lagPrimary === 'boolean' ? port.lagPrimary : !aggregationId,
      },
      updatingConfig: false,
      initialDiversityObject: {
        diversityOption: port.config?.diversityZone || null,
        diverseFrom: port.config?.diverseFrom || null,
      },
      diversitySetting: port.config?.diversityZone || null,
      diverseFrom: port.config?.diverseFrom || null,
      mcrConfigActiveTab: DETAILS,
      DETAILS,
      PREFIX_LISTS,
      deals: [],
    }
  },

  computed: {
    ...mapState('Services', ['services', 'locationsReady']),
    ...mapGetters('ApplicationContext', ['companyContextLoading']),
    ...mapGetters('Auth', ['isPartnerAccount', 'isPartnerVantage']),
    ...mapGetters('Services', ['filteredLocations', 'myPorts']),
    ...mapGetters('Company', ['companyUid']),

    // Step-related computed properties
    allSteps() {
      const steps = []

      if (this.lagInDesign) {
        steps.push({
          id: 'select-location',
          title: this.$t('general.select-location'),
          ready(self) {
            if (self.portObj.virtual !== null) return true

            return false
          },
        })
      }

      steps.push({
        id: 'configure-port',
        title: this.$t('general.configure'),
        ready(self) {
          if (!self.locationSelect || !self.locationSelect.id) return false

          return true
        },
      })

      steps.push({
        id: 'summary',
        title: this.$t('general.summary'),
        ready(self) {
          if (self.portObj.virtual === null || !self.locationSelect) return false
          if (!self.locationSelect.id) return false
          if (!self.createMegaportForm.productName) return false
          if (self.diversityObject.diversityOption === self.G_DIVERSITY_ZONE_PAIR && !self.createMegaportForm.secondPortName) return false
          if (!self.createMegaportForm.portSpeed) return false
          if (self.portObj.virtual) {
            let valid = true

            validateMcrAsn({ required: true }, self.createMegaportForm.mcrAsn, result => {
              if (result) valid = false
            })

            if (!valid) return false
          }

          return true
        },
      })

      return steps
    },
    currentStep() {
      return this.allSteps[this.stepIdx]
    },
    canNextStep() {
      if (this.isFinalStep) return true

      const nextStepIsReady = this.allSteps[this.stepIdx + 1].ready(this)

      return nextStepIsReady
    },
    isFinalStep() {
      if (this.allSteps.length === this.stepIdx + 1) return true

      return false
    },
    isSummaryStep() {
      return this.currentStep.id === 'summary'
    },

    // Availability/customer-type/permissions-related computed properties
    availableSpeeds() {
      const locationData = this.filteredLocations.find(location => location.id === this.createMegaportForm.locationSelectedId)

      if (this.isMcr) {
        // Can't even give a default option since MCR1 and MCR2 speeds don't overlap
        if (!locationData) return []

        return locationData.products.mcr2
      } else if (this.createMegaportForm.locationSelectedId) {
        if (locationData?.products?.megaport) {
          const sortedSpeeds = locationData.products.megaport
            .map(speed => speed * 1000)
            .sort((a, b) => a - b)

          return sortedSpeeds
        }
      }

      return [10000] // 10G will always be available
    },
    availableLagSlots() {
      // When someone is editing a designed LAG port which has connections on it, we
      // need to ensure that the available items in the LAG port array don't include
      // options to select a number which would leave the LAG without enough capacity
      // to support the connection.
      const currentInDesignPort = this.myPorts.find(port => port.productUid === this.$route.params.productUid) || {}
      const aggregationId = this.$route.params.aggregationId ? parseInt(this.$route.params.aggregationId) : null
      const lagPrimary = this.myPorts.find(port => port.lagPrimary && (port.aggregationId === aggregationId || port.aggregationId === currentInDesignPort.aggregationId)) || {}

      let takenLagSlots = 0
      let maxLagSlots = 8

      if (lagPrimary.productUid) {
        takenLagSlots = lagPrimary._subLags.filter(sl => sl.provisioningStatus !== this.G_PROVISIONING_DESIGN).length + 1
        maxLagSlots = 8 - takenLagSlots
      }

      let minLagSlots = 1

      if (currentInDesignPort.productUid) {
        // Design was already saved or existing LAG
        let portWithConnections = currentInDesignPort

        if (lagPrimary.productUid) {
          portWithConnections = lagPrimary
        }

        const allSpeeds = [].concat(
          ...portWithConnections.associatedIxs.map(ix => ix.rateLimit),
          ...portWithConnections.associatedVxcs.map(vxc => vxc.rateLimit)
        )

        const maxConnectionSpeed = Math.max(...allSpeeds)

        minLagSlots = Math.ceil(maxConnectionSpeed / portWithConnections.speed) - takenLagSlots
        minLagSlots = Math.max(minLagSlots, 1)
      }

      // If we get here, it's because the user lowered the rate limit of a port
      // while still having a connection with a higher rate limit
      if (minLagSlots > maxLagSlots) return []

      const availableLagSlots = Array.from(Array(maxLagSlots - minLagSlots + 1), (x, index) => index + minLagSlots)

      return availableLagSlots
    },
    allowNotify() {
      return !this.isPartnerAccount && !this.companyContextLoading
    },

    // Pricing-related computed properties
    showPricing() {
      return !this.disabledFeatures.showPrices
        && this.isFeatureEnabled('PRICING_VISIBLE')
        && this.currentStep.id !== 'configure-port'
    },
    price() {
      // No need to request for pricing when the feature is not enabled
      if (!this.showPricing) return false

      // No need to request for pricing if no location or port speed have been selected
      if (!this.locationSelect || !this.createMegaportForm.portSpeed) {
        // Empty the price store when the location or port speed are removed
        this.updatePriceStore(false)

        return false
      }

      // Send a request for pricing and set the price store
      this.refreshPriceStore()

      return this.priceStore
    },
    finalMonthlyRate() {
      if (!this.price) return

      let multiplier = 1

      if (this.createMegaportForm.lagPort || this.portObj.aggregationId) {
        multiplier = this.createMegaportForm.lagPortCount
      }

      if (this.allowsPortDiversity && this.diversityObject.diversityOption === this.G_DIVERSITY_ZONE_PAIR) {
        multiplier *= 2
      }

      return this.price.monthlyRate * multiplier
    },
    formattedFinalMonthlyRate() {
      if (!this.price) return

      return moneyFilter(this.finalMonthlyRate, this.price.currency)
    },

    // Diversity-related computed properties
    allowsPortDiversity() {
      // We don't want to show the diversity info at all if adding a port to an existing LAG
      // at a location where diversity is not available.
      if (this.portObj.aggregationId) {
        const locationData = this.filteredLocations.find(location => location.id === this.createMegaportForm.locationSelectedId)
        const diversityData = locationData?.diversityZones?.megaport

        if (!diversityData) return false

        // LAGs are always 10G ports
        const availableZones = diversityData.filter(item => item.speed === 10)

        if (availableZones.length < 2) return false
      }

      return !this.isMcr
    },
    diversePortName() {
      const diversePort = this.myPorts.find(port => port.productUid === this.diverseFrom)

      return diversePort?.productName ?? ''
    },
    diversityObject() {
      return {
        diversityOption: this.diversitySetting,
        diverseFrom: this.diverseFrom,
        firstPortName: this.createMegaportForm.productName,
        secondPortName: this.createMegaportForm.secondPortName,
      }
    },
    manuallySelectedDiversityZone() {
      return [G_DIVERSITY_ZONE_RED, G_DIVERSITY_ZONE_BLUE].includes(this.createMegaportForm.diversityZone)
    },

    // Location-related computed properties
    displayLocations() {
      return this.filteredLocations
        .filter(location => {
          const { diversityZones, market, products, status } = location

          // Filter out all locations with a status other than 'Deployment' or 'Active'
          if (!['Deployment', 'Active'].includes(status)) return false

          // Filter out all locations that don't offer MCRs if creating an MCR
          if (this.isMcr && !products.mcr2) return false

          // Filter out all locations in a country other than the originally used to create associated Megaport Internet VXCs when relevant
          if (this.hasMegaportInternetVxc && this.megaportInternetVxcLocation?.market !== market) return false

          // Filter MCRs by diversity zone
          if (this.isMcr && this.manuallySelectedDiversityZone) {
            return diversityZones?.mcr2?.some(zone => zone.name === this.createMegaportForm.diversityZone)
          }

          return true
        })
    },
    locationSelect: {
      get() {
        return this.filteredLocations.find(location => location.id === this.createMegaportForm.locationSelectedId) || null
      },
      set(val) {
        this.createMegaportForm.locationSelectedId = val.id
      },
    },

    // Port-type-related computed properties
    isMcr() {
      return this.portObj.productType === G_PRODUCT_TYPE_MCR2
    },

    // Other computed properties
    existingInDesignPort() {
      return this.myPorts.find(port => port.productUid === this.editingExistingUid)
    },
    hasMegaportInternetVxc() {
      return this.existingInDesignPort?.associatedVxcs.some(vxc => vxc.connectType === 'TRANSIT')
    },
    megaportInternetVxcLocation() {
      if (!this.hasMegaportInternetVxc) return null

      const megaportInternetVxc = this.existingInDesignPort.associatedVxcs.find((vxc => vxc.connectType === 'TRANSIT'))

      if (megaportInternetVxc) {
        const bEndLocationId = megaportInternetVxc.bEnd.locationId
        const bEndLocation = this.filteredLocations.find(location => location.id === bEndLocationId)

        return bEndLocation
      }

      return null
    },
    detailLines() {
      const detailLines = []

      detailLines.push(this.createMegaportForm.productName || this.$t('general.untitled'))
      detailLines.push(this.convertSpeed(this.createMegaportForm.portSpeed))
      detailLines.push(this.locationSelect ? this.locationSelect.formatted.short : this.$t('general.no-location'))

      return detailLines
    },
    minimumTerm() {
      if (this.createMegaportForm.term) {
        if (this.isMcr && this.createMegaportForm.term === 1) {
          return this.$t(this.isPartnerVantage ? 'partner-vantage.no-subscription' : 'port-term.no-minimum-term')
        }

        return this.$tc('general.count-months', this.createMegaportForm.term, { count: this.createMegaportForm.term })
      }

      return null
    },
    headerTitle() {
      return [
        this.fresh ? 'general.new-type' : 'general.edit-configured-type',
        { type: this.isMcr ? this.$t('productNames.mcr') : this.$t('productNames.port') },
      ]
    },
  },

  watch: {
    services: {
      deep: true,
      handler(newValue) {
        // Never need to bail if we weren't already saved
        if (!this.editingExistingUid) return

        let newPort = null

        if (newValue) {
          newPort = newValue.find(port => port.productUid === this.editingExistingUid)
        }

        if (!newPort) {
          const props = {
            title: this.$t('ports.port-deleted'),
            message: this.$t('ports.port-removed'),
            type: 'error',
            duration: 3000,
          }

          this.allowNotify && this.$notify(props)

          this.$router.push(resolveServicesPage())
        }
      },
    },
  },

  created() {
    this.$root.$on('createPortForm', value => {
      if (typeof value === 'object') {
        Object.keys(value).forEach(key => {
          if (typeof this.createMegaportForm[key] !== 'undefined') {
            this.createMegaportForm[key] = value[key]
          }
        })
      }
    })

    this.fetchDeals()

    if (this.$route.query.locationId) {
      this.createMegaportForm.locationSelectedId = parseInt(this.$route.query.locationId)
      this.$router.replace({ query: {} })
    }
  },

  beforeDestroy() {
    this.$root.$off('createPortForm')
  },

  methods: {
    ...mapActions('Services', ['addService', 'updateExistingService']),
    convertSpeed,
    setFallbackImage,
    resolveServicesPage,
    locationToString,
    sortLocationString,
    scopedPriceBook,

    fetchDeals() {
      const companyUid = this.companyUid || ''
      const standardDeal = {
        entityUid: 'None',
        dealId: '',
        opportunityName: 'None',
      }

      sdk.instance
        .partnerVantage()
        .deals(companyUid)
        .then(res => {
          if (res.length) {
            this.deals = [
              standardDeal,
              ...res,
            ]
            // sort in ascending by deal id
            this.deals = this.deals.sort((a, b) => a.dealId.toUpperCase().localeCompare(b.dealId.toUpperCase()))
          } else {
            this.deals = [standardDeal]
            this.createMegaportForm.dealUid = 'None'
          }
        })
        .catch(e => captureSentryError(e))
    },
    trackDocLinks() {
      this.trackButtonClick('docs-link')
      window.open('https://docs.megaport.com/connections/creating-port/', '_blank')
    },
    handleDiversityChange(value) {
      this.diversitySetting = value.diversityOption
      this.diverseFrom = value.diverseFrom

      if (value.firstPortName !== null) {
        this.createMegaportForm.productName = value.firstPortName
      }

      this.createMegaportForm.secondPortName = value.secondPortName || ''
    },
    formFieldValidated(field, valid, message) {
      if (!valid && field === 'mcrAsn') {
        if (message.length > 30) {
          this.longAsnError = true
        } else {
          this.longAsnError = false
        }
      }
    },
    handleStepClick(event, index) {
      if (!this.allSteps[index].ready(this)) {
        this.$alert(this.$t('general.step-unavailable'), this.$t('general.not-available'), {
          showClose: false,
          type: 'warning',
        })

        return
      }

      this.stepIdx = index
    },
    calcStepStatus(step, index) {
      if (!step.ready(this)) return 'wait'
      if (index < this.stepIdx) return 'success'
      if (index === this.stepIdx) return 'process'

      return 'finish'
    },
    /**
     * Location selection handler
     * @param {Number} value Selected location ID
     */
    handleLocationSelect({ value }) {
      this.createMegaportForm.locationSelectedId = value
      this.$refs.createMegaportForm.validateField('locationSelectedId')

      // We need to tidy up the port diversity settings if they are
      // set up to be diverse from a port at a different location.
      if (this.diverseFrom) {
        this.diversitySetting = null
        this.diverseFrom = null
      }

      // Check whether the speed that was previously selected is still available
      if (this.availableSpeeds && !this.availableSpeeds.includes(this.createMegaportForm.portSpeed)) {
        this.createMegaportForm.portSpeed = null
      }
    },
    updatePriceStore(val) {
      this.priceStore = val
    },
    refreshPriceStore() {
      if (this.isMcr) {
        this.scopedPriceBook()
          .mcr(
            this.locationSelect.id,
            this.createMegaportForm.portSpeed,
            this.portObj.productUid,
            this.createMegaportForm.term
          )
          .then(res => {
            this.priceStore = res
          })
          .catch(() => {
            // Error handled by the pricebook module.
            this.priceStore = false
          })
      } else {
        this.scopedPriceBook()
          .megaport(
            this.locationSelect.id,
            this.createMegaportForm.portSpeed,
            this.createMegaportForm.term,
            this.portObj.buyoutPort,
            this.portObj.productUid
          )
          .then(res => {
            this.priceStore = res
          })
          .catch(() => {
            // Error handled by the pricebook module.
            this.priceStore = false
          })
      }
    },
    goToNextStep() {
      if (this.isFinalStep) return this.addToCart()

      this.$refs.createMegaportForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }

          this.$notify(props)

          return false
        }

        // If we are going from the location step, calculate MCR diversity zone
        if (this.stepIdx === 0 && !this.manuallySelectedDiversityZone) {
          const { diversityZones } = this.locationSelect
          this.createMegaportForm.diversityZone = calculateAutoDiversity(G_PRODUCT_TYPE_MCR2, diversityZones)
        }

        this.trackButtonClick('next')
        this.stepIdx++
      })
    },
    goToLastStep() {
      this.trackButtonClick('back')
      this.stepIdx--
    },
    addToCart() {
      this.updatingConfig = true

      let portToBeAdded = {
        associatedIxs: [],
        associatedVxcs: [],
        buyoutPort: false,
        companyUid: this.companyUid,
        costCentre: this.createMegaportForm.costCentre,
        term: this.createMegaportForm.term,
        createDate: Date.now(),
        locationId: this.locationSelect.id,
        marketplaceVisibility: this.isMcr ? false : this.createMegaportForm.marketplaceVisibility,
        portSpeed: this.createMegaportForm.portSpeed,
        productName: this.createMegaportForm.productName,
        productType: this.G_PRODUCT_TYPE_MEGAPORT,
        productUid: this.portObj.productUid,
        provisioningStatus: this.G_PROVISIONING_DESIGN,
        virtual: this.isMcr,
        speed: this.createMegaportForm.portSpeed,
        config: {},
        uiConfig: {}, // Anything that is needed only while the port is being designed goes in here
      }

      if (this.createMegaportForm.dealUid) {
        const dealUid = this.createMegaportForm.dealUid === 'None' ? null : this.createMegaportForm.dealUid
        portToBeAdded = {
          ...portToBeAdded,
          dealUid,
        }
      }

      if (this.isMcr) {
        portToBeAdded.productType = this.G_PRODUCT_TYPE_MCR2
        portToBeAdded.config.bgpShutdownDefault = this.createMegaportForm.bgpShutdownDefault

        if (this.createMegaportForm.mcrAsn !== MEGAPORT_ASN) {
          portToBeAdded.config.mcrAsn = this.createMegaportForm.mcrAsn
        } else {
          // If the port was previously saved with a configured ASN and this time we
          // are setting it as the default ASN, then we need to explicitly send it
          // to the back end to make it be recognized.
          const port = this.myPorts.find(p => p.productUid === this.$route.params.productUid) || {}

          if (port.config && port.config.mcrAsn && port.config.mcrAsn !== MEGAPORT_ASN) {
            portToBeAdded.config.mcrAsn = MEGAPORT_ASN
          }
        }

        // MCR diversity zone
        if (this.manuallySelectedDiversityZone) {
          portToBeAdded.config.diversityZone = this.createMegaportForm.diversityZone
        }
      } else if (this.createMegaportForm.lagPort || this.portObj.aggregationId) {
        portToBeAdded.lagPortCount = this.createMegaportForm.lagPortCount

        if (this.portObj.aggregationId) {
          portToBeAdded.aggregationId = this.portObj.aggregationId
          portToBeAdded.lagId = this.portObj.lagId
        }
      }

      if (!this.isMcr) {
        if (this.diversitySetting === this.G_DIVERSITY_ZONE_PAIR) {
          portToBeAdded.config.diversityZone = this.G_DIVERSITY_ZONE_RED
          portToBeAdded.config.diverseFrom = null
        } else if (this.diversitySetting) {
          portToBeAdded.config.diversityZone = this.diversitySetting
          portToBeAdded.config.diverseFrom = this.diverseFrom
        } else {
          portToBeAdded.config.diverseFrom = null
          portToBeAdded.config.diversityZone = null
          delete portToBeAdded.uiConfig
        }
      }

      if (this.fresh) {
        this.addService(portToBeAdded)
      } else {
        this.updateExistingService(portToBeAdded)
      }

      if (!this.isMcr && this.diversitySetting === this.G_DIVERSITY_ZONE_PAIR) {
        portToBeAdded.productUid = uuid()
        portToBeAdded.config.diversityZone = this.G_DIVERSITY_ZONE_BLUE
        portToBeAdded.productName = this.createMegaportForm.secondPortName

        this.addService(portToBeAdded)
      }

      if (this.fresh) {
        this.$root.$emit('portAdded', portToBeAdded.productUid)
      }

      this.updatingConfig = false

      const props = {
        title: this.$t('general.success'),
        message: this.$t('services.saved'),
        type: 'success',
        duration: 4000,
      }

      this.$notify(props)
      this.trackButtonClick('finish')

      // If we don't have the concept of configured services, then we skip the whole aside and
      // push to services part, and go directly to deployment.
      if (this.disabledFeatures.configuredServices) {
        this.$root.$emit('showOrderPanel')
      } else {
        this.$router.push(resolveServicesPage())
        this.$root.$emit('showActionAside')
      }
    },
    handleCreateMegaportFormUpdate({ key, value }) {
      this.createMegaportForm = {
        ...this.createMegaportForm,
        [key]: value,
      }
    },
    handleCreateMegaportFormValidation({ key, valid, error }) {
      this.createMegaportRules = {
        ...this.createMegaportRules,
        [key]: {
          validator: valid ? null : customValidationRule(error),
          trigger: 'blur',
        },
      }
    },
    /** Reset location when changing diversity zone filter */
    resetLocation() {
      this.createMegaportForm.locationSelectedId = null
    },
    trackButtonClick(buttonName) {
      captureEvent(
        `create-${productTypeToEvent(this.portObj.productType)}.${this.currentStep.id}.${buttonName}.click`,
        buttonName === 'next' ? this.createMegaportForm : undefined
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.steps {
  padding: 1rem;
}

.how-to-create-port {
  text-align: center;
  padding: 2rem 0;
}

.how-to-create-port a {
  cursor: pointer;
  text-decoration: none;
}

.content-panel {
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 1rem;
  margin: auto;
  width: fit-content;
  min-width: 300px;
  position: relative;
}

.content-column {
  width: min-content;
  min-width: 500px;
  margin: 1rem;
}

.content-tabs {
  min-width: 700px;
}

svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5em;
  height: 2.5em;
  fill: currentColor;
}

.is-error .location-select-holder::v-deep .item-select-holder {
  border-color: var(--color-danger);
}
.is-success .location-select-holder::v-deep .item-select-holder {
  border-color: var(--color-success);
}
.summary-table .el-row {
  color: var(--color-text-regular);
  font-weight: 300;
  padding: 1rem;
  min-width: 400px;
  word-break: break-all;
  .el-col:first-of-type {
    font-weight: 500;
    text-align: right;
  }
  &.total {
    border-top: 1px solid var(--card-border-color);
    border-bottom: 1px solid var(--card-border-color);
  }
}

.width-140px {
  width: 140px;
}

.max-width-500px {
  max-width: 500px;
}

.mt-20-px {
  margin-top: 20px;
}

.selectable-item-cell .location-header {
  font-weight: 700;
}

.diversity-zone-icon {
  font-size: 2rem;
}
</style>

<style lang="scss">
.location-select-table table tr.location-row {
  background-color: #fbfbfb;
  &:hover > td {
    background-color: #fbfbfb;
  }
}

.location-select-table.el-table td {
  padding: 0;
  border: 0;
}
.location-select-table .location-cell {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin: 0.25rem 0;
}
.location-cell {
  background-color: var(--color-white);
  &:hover {
    border-color: var(--color-primary-light-5);
    background-color: var(--color-primary-light-9);
  }
  .location-header {
    font-weight: 700;
  }
}
.current-row .location-cell {
  .location-header {
    color: var(--color-primary);
  }
  border-color: var(--color-primary);
  background-color: var(--color-primary-light-8);
}
.location-select-table .el-table__body tr.current-row > td {
  background-color: #fbfbfb;
}
button.el-button.el-button--primary.is-plain.step-button {
  &:focus {
    background-color: var(--color-primary-light-9);
    color: var(--color-primary);
    border-color: var(--color-primary-light-6);
    outline: none;
  }
}

.country-filter .el-select-dropdown__item {
  line-height: 2rem;
  height: 2rem;
}

#tab-prefixLists {
  padding-right: 3.5rem;
}
</style>
